import { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export const useIsHighlightOptionPanelEnabled = () => {
	const { data, loading } = useQuery(
		gql`
			query useIsHighlightOptionPanelEnabledQuery {
				userPreferences {
					highlightOptionPanelEnabled
				}
			}
		`,
		{
			fetchPolicy: 'cache-and-network',
		},
	);

	return useMemo(() => {
		// We always want to default to true until we get a result
		let isHighlightOptionPanelEnabled = true;

		if (!loading && data) {
			isHighlightOptionPanelEnabled = data?.userPreferences.highlightOptionPanelEnabled;
		}

		return { isHighlightOptionPanelEnabled };
	}, [data, loading]);
};
